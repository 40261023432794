
import { Form as Validation } from 'vee-validate'
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import useLoader from '@/compositions/loader'
import SignWithButtons from '@/components/pages/auth/SignWithButtons.vue'

export default defineComponent({
  components: {
    SignWithButtons,
    Validation,
    TmButton,
    TmFormLine,
  },
  setup() {
    const login = ref({
      email: '',
      password: '',
    })

    const passwordField = ref()
    const passwordVisible = ref(false)
    const credentialsIncorrect = ref(false)

    const { showLoader, hideLoader } = useLoader({
      title: 'Logging you in securely...',
      subtitle: 'If this page appears for more than 5 seconds, <a onClick="window.location.reload()" href="#">click here</a> to reload.',
    })
    const next = () => {
      if (!passwordVisible.value) {
        passwordVisible.value = true
        return
      }
      showLoader()
      setTimeout(hideLoader, 10000)
      credentialsIncorrect.value = true
    }

    return {
      credentialsIncorrect,
      login,
      passwordField,
      passwordVisible,
      next,
    }
  },
})
