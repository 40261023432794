import useQuasar from 'quasar/src/composables/use-quasar.js';
import '@/styles/loader.scss'

const useLoader = ({ title, subtitle }: {title: string, subtitle: string}): {
  showLoader: () => void,
  hideLoader: () => void,
} => {
  const $q = useQuasar()
  const showLoader = () => {
    $q.loading.show({
      message: `
        <div class="text-center neutral--text subhead-regular-16">
            <p class="full-width-loader__title headline-18">${title}</p>
            ${subtitle}
        </div>`,
      html: true,
      spinnerColor: 'primary',
      customClass: 'full-width-loader',
    })
  }

  const hideLoader = () => {
    $q.loading.hide()
  }

  return {
    showLoader,
    hideLoader,
  }
}

export default useLoader
